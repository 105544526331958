<template>
  <el-dialog :visible.sync="visible" :title="dataForm.id ? '修改' : '新增'">
    <el-form label-width="100px" ref="formDom" :model="dataForm">
      <el-form-item label="角色名" prop="name">
        <el-input v-model="dataForm.name" placeholder="请输入角色名"></el-input>
      </el-form-item>
      <el-form-item label="级别" prop="name">
        <el-input v-model="dataForm.level" placeholder="请输入级别"></el-input>
      </el-form-item>
      <el-form-item label="菜单授权">
        <el-tree
          :data="menuList"
          :props="{ label: 'name', children: 'children' }"
          node-key="id"
          ref="menuListTreeDom"
          accordion
          show-checkbox
          :default-checked-keys="selectMenu"
          @check-change="currentMenuListChanger"
        ></el-tree>
      </el-form-item>
    </el-form>

    <template slot="footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="submitHandler">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: "",
        name: "",
        level: "",
        menuIds: [],
      },
      menuList: [],
      selectMenu: [],
    };
  },
  methods: {
    init(id) {
      this.dataInit();

      this.visible = true;
      this.getMenuList();
      if (id) {
        this.dataForm.id = id;
        this.getRoleInfo();
      }
    },
    // 初始化数据
    dataInit() {
      this.selectMenu = [];
      this.dataForm = {
        id: "",
        name: "",
        menuIds: [],
      };
      this.$nextTick(() => {
        this.$refs["formDom"].resetFields();
      });
    },
    // 获取菜单列表
    getMenuList() {
      this.$http.role.power().then((res) => {
        this.menuList = res.data || [];
      });
    },
    // 获取角色信息
    getRoleInfo() {
      this.$http.role.info({ id: this.dataForm.id }).then((res) => {
        this.dataForm = res.data || this.dataForm;
        console.log(res.data);
        this.selectMenu = [];
        this.dataForm.menus.forEach((ele) => {
          // 父
          if (ele.children && ele.children.length > 0) {
            // this.selectMenu.push(ele.id);
            ele.children.forEach((child) => {
              // 子
              // this.selectMenu.push(child.id);
              if (child.children && child.children.length > 0) {
                child.children.forEach((sun) => {
                  // 孙  只需要
                  this.selectMenu.push(sun.id);
                });
              } else {
                this.selectMenu.push(ele.id);
              }
            });
          } else {
            this.selectMenu.push(ele.id);
          }
        });
      });
    },
    // 当前选择菜单发生变化
    currentMenuListChanger() {
      let parentArr = this.$refs["menuListTreeDom"].getHalfCheckedKeys();
      let childArr = this.$refs["menuListTreeDom"].getCheckedKeys();

      this.dataForm.menuIds = Array.from(new Set(parentArr.concat(childArr)));
    },
    // 提交处理
    submitHandler() {
      if (this.dataForm.id) {
        this.updateRoleHandler();
      } else {
        this.addRoleHandler();
      }
    },
    // 新建角色
    addRoleHandler() {
      this.$http.role.save(this.dataForm).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
          duration: 500,
          onClose: () => {
            this.visible = false;
            this.$emit("refreshDataList");
          },
        });
      });
    },
    // 修改角色
    updateRoleHandler() {
      this.$http.role.update(this.dataForm).then((res) => {
        this.$message({
          message: res.msg,
          type: "success",
          duration: 500,
          onClose: () => {
            this.visible = false;
            this.$emit("refreshDataList");
          },
        });
      });
    },
  },
};
</script>