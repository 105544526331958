<template>
  <div class="mod-role">
    <div class="mod-header">
      <el-button type="primary" @click="openAddOrUpdComponent()"
        >新增</el-button
      >
    </div>

    <el-table :data="roleList" border>
      <el-table-column label="ID" prop="id" align="center" width="190"></el-table-column>
      <el-table-column
        label="角色名"
        prop="name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="级别"
        prop="level"
        align="center"
      ></el-table-column>

      <el-table-column label="创建时间" prop="createDate" align="center">
        <template slot-scope="scope">
          <div>
            {{ scope.row.createDate ? scope.row.createDate.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="updateDate" align="center">
        <template slot-scope="scope">
          <div>
            {{ scope.row.updateDate ? scope.row.updateDate.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            plain
            @click="openAddOrUpdComponent(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="danger"
            size="mini"
            plain
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <role-add-or-update
      v-if="roleAddOrUpd_visible"
      ref="roleAddOrUpdDom"
      @refreshDataList="getRoleList"
    ></role-add-or-update>
  </div>
</template>

<script>
import RoleAddOrUpdate from "./role-add-or-update.vue";

export default {
  components: {
    RoleAddOrUpdate,
  },
  data() {
    return {
      roleList: [],
      roleAddOrUpd_visible: false,
    };
  },
  created() {
    this.getRoleList();
  },
  methods: {
    // 获取角色列表
    getRoleList() {
      this.$http.role.list().then((res) => {
        if (!res.data) return;
        this.roleList = res.data || [];
      });
    },
    // 删除
    deleteHandle(id) {
      // 全局mixin混入事件；val输入的备注(then：确认按钮后的操作；catch：取消后的操作)
      this.remarksDelete()
        .then((val) => {
          this.$http.role
            .delete({
              id,
              remarks: val,
            })
            .then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: data.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getRoleList();
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
        })
        .catch(() => {});
    },
    // 打开新增或修改角色组件
    openAddOrUpdComponent(id) {
      this.roleAddOrUpd_visible = true;
      this.$nextTick(() => {
        this.$refs.roleAddOrUpdDom.init(id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mod-header {
  margin-bottom: 20px;
}
</style>